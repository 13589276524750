import { COUNTRIES_MAP } from 'constants/index';
import { ISearchSelectLocationData } from 'types';
import omit from 'lodash.omit';

export const formatAddress = ({
  country = 'unknown',
  province,
  city,
  streetAddress,
  postCode,
}: {
  country?: string;
  province?: string;
  city?: string;
  streetAddress?: string;
  postCode: string;
}): string => {
  return `${COUNTRIES_MAP[country] || country}, ${province}, ${city}, ${streetAddress} ${postCode}`.replace(' ,', '');
};

export const formatLocation = (address: ISearchSelectLocationData) => {
  const locationKeys = omit(address, ['latitude', 'longitude', 'countryCode']);
  return Object.values(locationKeys).reduce((acc: string, item: string) => (item ? acc + `, ${item}` : ''));
};

export const formatWalletAddress = (accountAddress = ''): string => {
  return accountAddress.length > 5 ? `${accountAddress.slice(0, 5)}...${accountAddress.slice(-4)}` : accountAddress;
};

export const formatHashRequest = (accountAddress = ''): string => {
  return accountAddress.toLowerCase().replace(/^0x/, '');
};

export const formatTxHash = (accountAddress = ''): string => {
  return accountAddress.length > 5 ? `0x${accountAddress.slice(0, 6)}...${accountAddress.slice(-6)}` : accountAddress;
};

export const formatCash = (value: number): string | number => {
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(3) + 'k';
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(3) + 'm';

  return value;
};

export const getPriceForToken = (bidPrice: number, amountToBuy: number) => {
  const priceForToken = bidPrice * amountToBuy;
  return formatPrice(priceForToken);
};

const formatPrice = (price: number, decimals = 10) => {
  if (Number.isInteger(price)) {
    return price.toString();
  }

  return parseFloat(price.toFixed(decimals)).toString();
};

export const formatLongString = (str: string, limit = 20): string => {
  return str.length > limit ? `${str.slice(0, limit - 4)}...${str.slice(-4)}` : str;
};

export const insertAt = (str: string, sub: string, pos: number) => {
  return `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
};

export const cutLongProjectType = (text: string): string => {
  if (text.length < 21) return text;
  return `${text.slice(0, 18)}...`;
};

export const getAbbreviation = (str: string, secondLetter = false): string => {
  if (str.indexOf(' ') < 0) {
    const tickerLength = secondLetter ? 4 : 3;
    return `${str[0]}${str.slice(1).replace(/[aeiou]/gi, '')}`.slice(0, tickerLength).toUpperCase();
  }
  const matchRes = secondLetter ? str.match(/\b([A-Za-z0-9][A-Za-z0-9]?)/g) : str.match(/\b([A-Za-z0-9])/g);
  return matchRes?.join('').toUpperCase() || str?.replace(' ', '');
};
