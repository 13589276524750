import { ethers } from 'ethers';
import { JsonRpcSigner } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

import appConfig from 'config/appConfig';
import { Provider } from '../Provider';
import { ZERO_ADDRESS } from 'constants/blockchain';

export class AuctionContract {
  static publicContract = new ethers.Contract(
    appConfig.contracts.Auction.address,
    appConfig.contracts.Auction.abi,
    Provider.rpcProvider,
  );
  contract: ethers.Contract;

  constructor(signer: JsonRpcSigner) {
    this.contract = new ethers.Contract(appConfig.contracts.Auction.address, appConfig.contracts.Auction.abi, signer);
  }

  static async getAuction(auctionStarter: string) {
    return this.publicContract.getAuction(auctionStarter);
  }

  async createAuction(
    tokenAddr: string,
    amountToSell: string,
    startPrice: string,
    startTime: number,
    endTime: number,
    paymentToken = ZERO_ADDRESS,
  ): Promise<TransactionResponse> {
    return this.contract.createAuction(
      tokenAddr,
      ethers.utils.parseEther(amountToSell).toString(),
      paymentToken,
      ethers.utils.parseEther(startPrice).toString(),
      Math.floor(startTime / 1000),
      Math.floor(endTime / 1000),
    );
  }

  async makeBid(
    auctionStarter: string,
    auctionId: string,
    amountToBuy: string,
    ccToBid: string,
  ): Promise<TransactionResponse> {
    return this.contract.makeBid(
      auctionStarter,
      auctionId,
      ethers.utils.parseEther(amountToBuy).toString(),
      ethers.utils.parseEther(ccToBid).toString(),
      {
        value: ethers.utils.parseEther(ccToBid).toString(),
      },
    );
  }

  async endAuction(auctionStarter: string): Promise<TransactionResponse> {
    const bids = (await this.contract._auctions(auctionStarter))[0][0];
    return this.contract.endAuction(auctionStarter, bids.length);
  }

  private async getOperatorRole() {
    return this.contract.OPERATOR_ROLE();
  }

  async grantOperatorRole(accountAddress: string) {
    const operatorRole = await this.getOperatorRole();

    return this.contract.grantRole(operatorRole, accountAddress);
  }

  async hasOperatorRole(accountAddress: string): Promise<boolean> {
    const operatorRole = await this.getOperatorRole();

    return this.contract.hasRole(operatorRole, accountAddress);
  }

  async revokeOperatorRole(accountAddress: string) {
    const operatorRole = await this.getOperatorRole();

    return this.contract.revokeRole(operatorRole, accountAddress);
  }

  async cancelAuction(auctionStarter: string): Promise<TransactionResponse> {
    return this.contract.cancelAuction(auctionStarter);
  }
}
