import { useStoreState } from 'easy-peasy';

import { TokenContract } from 'blockchain';
import useFetch from 'hooks/useFetch';
import { IStoreModel, IWalletState } from 'types';

import styles from './ProjectBalance.module.scss';
import { useState } from 'react';
import { Skeleton } from 'antd';

interface IProps {
  tokenAddr: string;
  tokenTicker?: string | null;
}

const ProjectBalance = ({ tokenAddr, tokenTicker = 't CO2' }: IProps) => {
  const wallet = useStoreState<IStoreModel, IWalletState>((store) => store.blockchain.wallet);
  const [isLoading, setIsLoading] = useState(true);
  const { response: tokenBalance } = useFetch(async () => {
    if (!wallet.walletAddress) {
      setIsLoading(false);
      return null;
    }
    try {
      const contract = new TokenContract(tokenAddr);
      const balance = await contract.balanceOf(wallet.walletAddress);
      return balance;
    } catch (error) {
      console.warn('Error fetching token balance:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [wallet.walletAddress, tokenAddr]);

  return (
    <div className={styles.tokenBalance}>
      {isLoading ? (
        <Skeleton.Button active={isLoading} shape='round' />
      ) : tokenBalance === null ? (
        '0.00'
      ) : (
        (+tokenBalance).toFixed(2)
      )}
      <span className={styles.tokenTicker}>{tokenTicker}</span>
    </div>
  );
};

export default ProjectBalance;
